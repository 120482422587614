
import React from 'react';
import Layout from "../components/layout";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import Nav from "../components/Nav"
import BlogList from "../components/BlogList"
import { graphql } from "gatsby"

export const query = graphql`  
    query($limit:Int!, $skip:Int!, $categorySlug:String!){
        allStrapiBlogArticle(skip: $skip, sort: {fields: updated_at, order: DESC}, limit: $limit, filter: {categories: {elemMatch: {slug: {eq: $categorySlug}}}}) {
            totalCount
            nodes {
                id
                shortdescription
                title
                slug
                image {
                    sharp: childImageSharp {
                        fluid( maxWidth: 770 ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
            pageInfo {
                currentPage
                itemCount
                pageCount
                perPage
            }
        }
        allStrapiCategory(filter: {slug: {eq: $categorySlug}}) {
            nodes {
                name
                slug
            }
        }
           
    }
`
const BlogCategoryPage = ({data}) => {
  return (
    <Layout
      title={"Rolteca | " + data.allStrapiCategory.nodes[0].name}
      description="Novedades, reviews y recomendaciones sobre juegos de rol y de mesa. No te pierdas ninguna novedad "
      slug={data.allStrapiCategory.slug}>
      <Nav />
      <PageHeader title={data.allStrapiCategory.nodes[0].name} />
      <BlogList
        elements={data.allStrapiBlogArticle.nodes}
        pageInfo={data.allStrapiBlogArticle.pageInfo}
        category={data.allStrapiCategory.nodes[0]}
      />
      <Footer />
    </Layout>
  );
};

export default BlogCategoryPage;
